<template>
  <b-form-group
    label="Ana Ürün Grubu"
    label-for="id_product_groups_main"
  >
    <validation-provider
      #default="{ errors }"
      name="Ana Ürün Grubu"
      rules="required"
    >
      <v-select
        id="id_product_groups_main"
        v-model="itemData.id_product_groups_main"
        label="title"
        placeholder="Seçiniz"
        :reduce="item => item.id"
        :options="mainGroups"
        :disabled="!itemData.id_brands"
        @input="getSubGroups"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'ProductGroups',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
      productGroups: [],
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['products/dataItem']
    },
    mainGroups() {
      return this.$store.getters['productGroups/mainDataList']
    },
  },
  created() {
    localize('tr')
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('productGroups/getMainDataList', {
        select: [
          'product_groups.id AS id',
          'product_groups.title AS title',
        ],
        where: {
          'product_groups.id_product_groups': null,
          'product_groups.id_brands': this.itemData.id_brands,
        },
      })
    },
    getSubGroups() {
      this.itemData.id_product_groups = null
      if (this.itemData.id_product_groups_main) {
        this.$store.dispatch('productGroups/getDataList', {
          select: [
            'product_groups.id AS id',
            'product_groups.title AS title',
            'main_groups.title AS main_group',
          ],
          where: {
            'product_groups.id_product_groups': this.itemData.id_product_groups_main,
          },
        })
      }
    },
  },
}
</script>
