<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <brand-select />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <main-product-group />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <product-group />
        </b-col>
        <b-col cols="12">
          <title-name />
        </b-col>
        <b-col cols="12">
          <content-text />
        </b-col>
        <b-col cols="12">
          <technical />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12">
        <photo-gallery />
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BButton,
} from 'bootstrap-vue'

import TitleName from '@/views/Admin/Products/elements/Title.vue'
import ContentText from '@/views/Admin/Products/elements/Content.vue'
import Technical from '@/views/Admin/Products/elements/Technical.vue'
import ProductGroup from '@/views/Admin/Products/elements/ProductGroup.vue'
import MainProductGroup from '@/views/Admin/Products/elements/MainProductGroup.vue'
import BrandSelect from '@/views/Admin/Products/elements/Brand.vue'
import PhotoGallery from '@/views/Admin/Products/elements/PhotoGallery.vue'

export default {
  name: 'ProductCategoryForm',
  components: {
    TitleName,
    ContentText,
    Technical,
    MainProductGroup,
    BrandSelect,
    ProductGroup,
    PhotoGallery,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['products/dataItem']
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
