<template>
  <b-card title="Fotoğraf Galerisi">
    <div class="mb-2">
      <b-form-group
        label="Görsel Yükle"
        label-for="image-upload"
      >
        <b-form-file
          id="image-upload"
          v-model="itemData.upload_images"
          placeholder="Bir görsel seçin veya buraya bırakın..."
          drop-placeholder="Görseli buraya bırakın..."
          browse-text="Görsel Seçin"
          multiple
        />
      </b-form-group>
    </div>
    <div
      v-if="images.length > 0"
      class="border-top pt-1"
    >
      <draggable
        v-model="images"
        tag="ul"
        class="cursor-move p-0 row match-height"
      >
        <div
          v-for="(image, index) in images"
          :key="index"
          tag="li"
          class="col-auto col-md-2"
        >
          <div class="border-light rounded p-1 mb-2 bg-white">
            <div class="bg-light height-200 overflow-hidden">
              <b-img
                :src="imageUrl + '/media/products/' + image.filename"
                fluid
                class="h-auto w-100"
              />
            </div>
            <div class="text-center border-top mt-1">
              <b-button
                variant="outline-danger"
                size="sm"
                pill
                class="btn-icon mt-1"
                @click="removeImage(image.id)"
              >
                <FeatherIcon icon="TrashIcon" />
              </b-button>
            </div>
          </div>
        </div>
      </draggable>
      <b-alert
        show
        variant="info"
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" /> Fotoğrafları sıralamak için sürükleyin.
        </div>
      </b-alert>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BButton, BAlert, BFormGroup, BFormFile,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'

export default {
  name: 'PhotoGallery',
  components: {
    BCard,
    BImg,
    BButton,
    BFormGroup,
    BFormFile,
    BAlert,
    draggable,
  },
  data() {
    return {
      imageUrl: this.$store.state.app.baseURL,
      images: [],
    }
  },
  computed: {
    itemData() {
      const data = this.$store.getters['products/dataItem']
      if (data) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.images = data.images
      }
      return data
    },
  },
  watch: {
    images() {
      this.$store.dispatch('products/reOrderingImages', this.images)
    },
  },
  methods: {
    removeImage(id) {
      this.$store.dispatch('products/removeImageData', id)
    },
  },
}
</script>
